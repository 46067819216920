<template>
  <div>
    Allgemeine Geschäftsbedingungen der alinotec GmbH & Co. KG für den Kauf des
    Produkts AlinoCam mit Betriebsleistungen zur Baustellendokumentation
    <ol class="roman">
      <li>
        Geltungsbereich
        <ol>
          <li>
            Diese Allgemeinen Geschäftsbedingungen regeln die Rechtsbeziehungen
            zwischen der der alinotec GmbH & Co. KG- im folgenden „alinotec“ und
            deren Kunden in Bezug auf das der Baustellen-Dokumentation dienende
            Produkt AlinoCam.
          </li>
        </ol>
      </li>
    </ol>

    I. Geltungsbereich 1.1 Diese Allgemeinen Geschäftsbedingungen regeln die
    Rechtsbeziehungen zwischen der der alinotec GmbH & Co. KG- im folgenden
    „alinotec“ und deren Kunden in Bezug auf das der Baustellen-Dokumentation
    dienende Produkt AlinoCam. 1.2 Diese Verkaufsbedingungen gelten
    ausschließlich gegenüber Unternehmern, juristischen Personen des
    öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen im Sinne von
    § 310 Absatz 1 BGB. Entgegenstehende oder von unseren Allgemeinen
    Geschäftsbedingungen abweichenden Bedingungen des Kunden erkennen wir nur
    an, wenn wir ausdrücklich schriftlich der Geltung zustimmen. 1.3 Diese
    Allgemeine Geschäftsbedingungen gelten auch für alle zukünftigen Geschäfte
    mit dem Kunden, soweit es sich um Rechtsgeschäfte verwandter Art handelt.
    II. Kaufvertrag 1. Überlassene Unterlagen An allen in Zusammenhang mit der
    Auftragserteilung dem Kunden überlassenen Unterlagen, wie z. B.
    Kalkulationen, Zeichnungen etc., behalten wir uns Eigentums- und
    Urheberrechte vor. Diese Unterlagen dürfen Dritten nicht zugänglich gemacht
    werden, es sei denn, wir erteilen dazu dem Kunden unsere ausdrückliche
    schriftliche Zustimmung. Soweit wir das Angebot des Kunden nicht innerhalb
    der Frist von § 2 annehmen, sind diese Unterlagen uns unverzüglich
    zurückzusenden. 2. Kaufpreis und Zahlung 2.1 Sofern nichts Gegenteiliges
    schriftlich vereinbart wird, gelten unsere Preise ab Werk ausschließlich
    Verpackung und zuzüglich Umsatzsteuer in jeweils gültiger Höhe. Kosten der
    Verpackung werden gesondert in Rechnung gestellt. 2.2 Die Zahlung des
    Kaufpreises hat ohne Abzug von Skonto zu erfolgen. 2.3 Sofern nichts anderes
    vereinbart wird, ist der Kaufpreis innerhalb von 10 nach Lieferung zu
    zahlen. Verzugszinsen werden in Höhe von 9%-Punkten über dem jeweiligen
    Basiszinssatz p.a. berechnet. Die Geltendmachung eines höheren
    Verzugsschadens bleibt vorbehalten. 3. Aufrechnung und
    Zurückbehaltungsrechte 3.1 Dem Kunden steht das Recht zur Aufrechnung nur
    zu, wenn seine Gegenansprüche rechtskräftig festgestellt oder unbestritten
    sind. Zur Ausübung eines Zurückbehaltungsrechts ist der Kunde nur insoweit
    befugt, als sein Gegenanspruch auf dem gleichen Vertragsverhältnis beruht 4.
    Lieferzeit 4.1 Der Beginn der von uns angegebenen Lieferzeit setzt die
    rechtzeitige und ordnungsgemäße Erfüllung der Verpflichtungen des Kunden
    voraus. Die Einrede des nicht erfüllten Vertrages bleibt vorbehalten. 4.2
    Tritt der Kunde einseitig vom Vertrag zurück oder kommt in Annahmeverzug
    oder verletzt schuldhaft sonstige Mitwirkungspflichten, so sind wir
    berechtigt, den uns insoweit entstehenden Schaden, einschließlich etwaiger
    Mehraufwendungen und entgangenem Gewinn ersetzt zu verlangen (nach BGB).
    Weitergehende Ansprüche bleiben vorbehalten. Sofern vorstehende
    Voraussetzungen vorliegen, geht die Gefahr eines zufälligen Untergangs oder
    einer zufälligen Verschlechterung der Kaufsache in dem Zeitpunkt auf den
    Kunden über, in dem dieser in Annahme- oder Schuldnerverzug geraten ist. 5.
    Gefahrübergang Bei Versendung wird die Ware auf Wunsch des Kunden an diesen
    versandt, so geht mit der Absendung an den Kunden, spätestens mit Verlassen
    des Werks/Lagers die Gefahr des zufälligen Untergangs oder der zufälligen
    Verschlechterung der Ware auf den Kunden über. Dies gilt unabhängig davon,
    ob die Versendung der Ware vom Erfüllungsort erfolgt oder wer die
    Frachtkosten trägt. 6. Eigentumsvorbehalt 6.1 Wir behalten uns das Eigentum
    an der gelieferten Sache bis zur vollständigen Zahlung sämtlicher
    Forderungen aus dem Liefervertrag vor. Dies gilt auch für alle zukünftigen
    Lieferungen, auch wenn wir uns nicht stets ausdrücklich hierauf berufen. Wir
    sind berechtigt, die Kaufsache zurückzunehmen, wenn der Kunde sich
    vertragswidrig verhält. 6.2 Der Kunde ist verpflichtet, solange das Eigentum
    noch nicht auf ihn übergegangen ist, die Kaufsache pfleglich zu behandeln.
    Insbesondere ist er verpflichtet, diese auf eigene Kosten gegen Diebstahl-,
    Feuer- und Wasserschäden ausreichend zum Neuwert zu versichern. Solange das
    Eigentum noch nicht übergegangen ist, hat uns der Kunde unverzüglich
    schriftlich zu benachrichtigen, wenn der gelieferte Gegenstand gepfändet
    oder sonstigen Eingriffen Dritter ausgesetzt ist. Soweit der Dritte nicht in
    der Lage ist, uns die gerichtlichen und außergerichtlichen Kosten einer
    Klage gemäß § 771 ZPO zu erstatten, haftet der Kunde für den uns
    entstandenen Ausfall. 7. Gewährleistung und Mängelrüge 7.1
    Gewährleistungsrechte des Kunden setzen voraus, dass dieser seinen nach §
    377 HGB geschuldeten Untersuchungs- und Rügeobliegenheiten ordnungsgemäß
    nachgekommen ist. 7.2 Ansprüche des Kunden wegen Sachmängeln verjähren in
    einem Jahr ab Übergabe/Ablieferung des Kaufgegenstandes an den Kunden.
    Hiervon ausgenommen sind Schadensersatzansprüche wegen der Verletzung von
    Leben, Körper oder Gesundheit und/oder Schadensersatzansprüche aufgrund von
    grob fahrlässig oder vorsätzlich verursachten Schäden durch alinotec.
    Insoweit gelten die gesetzlichen Verjährungsfristen. 7.3 Sollte trotz aller
    aufgewendeter Sorgfalt die gelieferte Ware einen Mangel aufweisen, der
    bereits zum Zeitpunkt des Gefahrübergangs vorlag, so werden wir die Ware,
    vorbehaltlich fristgerechter Mängelrüge nach unserer Wahl nachbessern oder
    Ersatzware liefern. Es ist uns stets Gelegenheit zur Nacherfüllung innerhalb
    angemessener Frist zu geben. Rückgriffsanspruche bleiben von vorstehender
    Regelung ohne Einschränkung unberührt. 7.4 Schlägt die Nacherfüllung fehl,
    kann der Kunde – unbeschadet etwaiger Schadenersatzansprüche – vom Vertrag
    zurücktreten oder die Vergütung mindern. 7.5 Mängelansprüche bestehen nicht,
    bei nur unerheblicher Abweichung von der vereinbarten Beschaffenheit, bei
    nur unerheblicher Beeinträchtigung der Brauchbarkeit, bei natürlicher
    Abnutzung oder Verschleiß, wie bei Schäden, die nach dem Gefahrübergang
    infolge fehlerhafter oder nachlässiger Behandlung, übermäßiger
    Beanspruchung, ungeeigneter Betriebsmittel, mangelhafter Bauarbeiten,
    ungeeigneten Baugrundes oder aufgrund besonderer äußerer Einflüsse
    entstehen, die nach dem Vertrag nicht vorausgesetzt sind. Werden vom Kunden
    oder Dritten unsachgemäß Instandsetzungsarbeiten oder Änderungen
    vorgenommen, so bestehen für diese und die daraus entstehenden Folgen
    ebenfalls keine Mängelansprüche. Dies gilt insbesondere im Falle der
    Nichtbeachtung unserer Montageanleitungen sowie beim Einbau von Fremdteilen.
    7.6 Ansprüche des Kunden wegen der zum Zweck der Nacherfüllung
    erforderlichen Aufwendungen, insbesondere Transport-, Wege-, Arbeits- und
    Materialkosten, sind ausgeschlossen, soweit die Aufwendungen sich erhöhen,
    weil die von uns gelieferte Ware nachträglich an einen anderen Ort als die
    Niederlassung des Kunden verbracht worden ist, es sei denn, die Verbringung
    entspricht ihrem bestimmungsgemäßen Gebrauch. III. Betriebsleistungen zur
    Baustellendokumentation 1. Vertrag über die Betriebsleistungen 1.1. Der
    Vertrag über die Betriebsleistungen von alinotec zur Baustellendokumentation
    wird über die vereinbarte Laufzeit abgeschlossen. alinotec behält sich das
    Recht zur ordentlichen Kündigung des Vertrages vor, wenn der Kunde mit einer
    Zahlung in Verzug ist, welche die Höhe von zwei der vereinbarten
    Bruttomonatsraten erreicht. Während der vereinbarten Laufzeit ist der
    Vertrag ansonsten von beiden Vertragsparteien nur aus wichtigem Grund
    kündbar. Die Kündigung bedarf der Schriftform. 1.2 Wird der Vertrag von
    alinotec vor Ablauf der vereinbarten Laufzeit gekündigt, wird der Vertrag,
    unter angemessener Abzinsung der außenstehenden Monatsrate und Abzug der
    alinotec ersparten Kosten, abgerechnet. 1.3 Sofern der Vertrag für eine
    Laufzeit von mindestens zwei Monaten geschlossen ist, verlängert sich der
    Vertrag um jeweils einen Monat, sofern er nicht zwei Wochen vor seinem
    Auslaufen schriftlich gekündigt wird. 2. Zahlung 2.1. Ein vereinbartes
    monatliches Entgelt ist am Tag des Beginns der Betriebsleistungen zur
    Baustellendokumentation und jeweils an dem Tag eines jeden Monats, dessen
    Datum dem Tag der Übernahme entspricht, im Voraus fällig. 2.2 Ein
    vereinbartes wöchentliches Entgelt ist am Tag des Beginns der
    Betriebsleistungen zur Baustellendokumentation für die gesamte vertragliche
    Laufzeit im Voraus fällig. 2.2 Der Kunde verpflichtet sich am
    Abbuchungsverfahren teilzunehmen. Zu diesem Zwecke ermächtigt er alinotec
    die Abbuchung, entsprechend der vom Kunden erteilten Einzugsermächtigung,
    von seinem Konto vorzunehmen. 3. Betriebsleistungen von alinotec zur
    Baustellendokumentation 3.1 Der Vertrag bzw. die Betriebsleistungen zur
    Baustellendokumentation beginnen an dem, der Bereitstellung der Zugangsdaten
    zur AlinoCam-Plattform folgenden Tag um 0.00 Uhr. 3.2 alinotec gewährleistet
    eine Erreichbarkeit seiner Server von 99 % im Jahresmittel. Hiervon
    ausgenommen sind Zeiten, in denen der Server aufgrund von technischen oder
    sonstigen Problemen, die nicht im Einflussbereich von alinotec liegen -
    höhere Gewalt, Verschulden Dritter usw. - übers Internet nicht zu erreichen
    ist. Alinotec kann den Zugang zu den Leistungen beschränken, sofern die
    Sicherheit des Netzbetriebes, die Aufrechterhaltung der Netzintegrität,
    insbesondere die Vermeidung schwerwiegender Störungen des Netzes, der
    Software oder gespeicherter Daten dies erfordern. 3.3 Mit der
    Unterschriftsleistung unter diesem Vertrag, erklärt sich der Kunde bereits
    jetzt damit einverstanden, dass alinotec den Vertrag mit allen Rechten und
    Pflichten an Dritte übertragen kann. alinotec wird dafür Sorge tragen, dass
    dem Kunden durch die Vertragsübernahme keine Nachteile in sachlicher oder
    finanzieller Hinsicht entstehen, insbesondere, dass die Betriebsleistungen
    zur Baustellendokumentation in dem vertraglich vereinbarten Umfang erfüllt
    werden. 4. Haftungsbegrenzung 4.1 Soweit zwischen den Vertragsparteien oder
    in diesen Geschäftsbedingungen nicht ausdrücklich Abweichendes bestimmt ist,
    sind Schadensersatzansprüche des Kunden und Ansprüche des Kunden auf
    Aufwendungsersatz – gleich aus welchem Rechtsgrunde – insbesondere wegen
    Verletzung von Pflichten aus dem Schuldverhältnis und aus unerlaubter
    Handlung, ausgeschlossen. alinotec haftet nicht für entgangenen Gewinn,
    entgangene Gebrauchsmöglichkeiten, Ansprüche Dritter und/oder jegliche
    sonstige mittelbare und Folgeschäden. 4.2 Der Haftungsausschluss gemäß
    Ziffer 5.1 gilt nicht (a) im Falle vorsätzlichen oder grob fahrlässigen
    Verhaltens von alinotec oder ihrer Erfüllungsgehilfen; (b) im Falle des
    Todes oder der Verletzung von Leib oder Gesundheit einer Person; (c) soweit
    alinotec für Personenschäden oder Sachschäden an Privatvermögen gemäß
    zwingenden Bestimmungen des anwendbaren Produkthaftungsrechtes haftet;
    und/oder (d) soweit alinotec ausnahmsweise eine Garantiehaftung für die
    Beschaffenheit von Vertragsleistungen übernommen haben sollte. 4.3 Der
    Haftungsausschluss gemäß Ziffer 14.1 gilt ferner nicht im Falle der
    Verletzung wesentlicher Vertragspflichten durch alinotec. Im Falle der
    Verletzung wesentlicher Vertragspflichten ist die Haftung von alinotec
    jedoch auf den vorhersehbaren und vertragstypischen Schaden begrenzt. Die
    Haftung von alinotec gemäß Ziffer 5.2 bleibt unberührt. 4.4 Die
    Baustellendokumentation durch alinotec kann das Schadensrisiko für den
    Kunden verringern. alinotec kann jedoch keine Garantie dafür geben, dass
    Schadensfälle vermieden werden. Die Baustellendokumentation durch alinotec
    ersetzt also keineswegs den Abschluss von einschlägigen Versicherungen. 5.
    Nutzungsreche am Bildmaterial 5.1 Die Aufnahme von Bildern durch alinotec,
    deren Übertragung an alinotec sowie deren Wiedergabe und Speicherung durch
    alinotec erfolgen ausschließlich im Auftrag und auf Risiko des Kunden.
    alinotec verpflichtet sich, das Bildmaterial ausschließlich für den Zweck
    der Erbringung der Vertragsleistungen zu verwenden. Jegliche Verwendung für
    andere Zwecke bedarf der Zustimmung des Kunden. 5.2 Der Kunde trägt die
    Verantwortung dafür, dass die Aufnahme, Übertragung, Wiedergabe und
    Speicherung des Bildmaterials über alinotec den gesetzlichen Bestimmungen
    insbesondere des Datenschutzrechts und des Schutzes von
    Persönlichkeitsrechten (insbesondere des Rechtes am eigenen Bild)
    entspricht. Die Vertragsparteien werden einander im Rahmen des gesetzlich
    Zulässigen unverzüglich informieren, wenn Dritte oder Behörden ihnen
    gegenüber geltend machen, dass durch die Aufnahme, Übertragung, Wiedergabe
    und Speicherung des Bildmaterials über AlinoCam gegen gesetzliche und/oder
    behördliche Vorschriften und/oder gegen Rechte Dritter verstoßen wird. Außer
    in dem Fall eines Verstoßes gegen Ziffer 5.1 Satz 2 stellt der Kunde
    alinotec von jeglichen Ansprüchen Dritter frei, soweit durch die im Rahmen
    der Leistungserbringung von alinotec erstellten Aufnahmen, Übertragungen,
    Wiedergaben und/oder Speicherungen des Bildmaterials Rechte Dritter verletzt
    werden oder gegen gesetzliche Vorschriften verstoßen wird und alinotec
    insofern durch Dritte oder Behörden in Anspruch genommen wird. Die
    Freistellung bezieht sich auf sämtliche Ansprüche des Dritten sowie auf die
    hierbei entstehenden eigenen notwendigen Auslagen der Rechtsverfolgung
    und/oder der Rechtsverteidigung. 6. Pflichten des Kunden 6.1 Der Kunde ist
    für alle Aktivitäten, die über sein Kundenkonto ausgeübt werden,
    verantwortlich. Der Kunde verpflichtet sich, von alinotec zum Zwecke des
    Zugangs zu deren Diensten erhaltener Passwörter streng geheim zu halten und
    alinotec unverzüglich zu informieren, sobald er davon Kenntnis erlangt, dass
    unbefugten Dritten das Passwort bekannt ist. Sollte infolge Verschuldens des
    Kunden Dritte durch Gebrauch der Passwörter Leistungen von alinotec nutzen,
    haftet der Kunde gegenüber alinotec auf Nutzungsentgelt und Schadensersatz.
    6.2 Zur Nutzung der Dienste von alinotec muss der Kunde eine gültige
    E-Mail-Adresse besitzen, die er regelmäßig und fortlaufend abruft. Diese
    E-Mail-Adresse muss der Kunde alinotec mitteilen, damit alinotec den Kunden
    kurzfristig für Mitteilungen erreichen kann. 7. Hinweise zur
    Datenübertragung – Kosten der Datenübertragung 7.1 Das Produkt AlinoCam
    verfügt über eine Netzwerkschnittstelle und baut automatisch, sofern
    technisch möglich, eine Verbindung in das Internet auf (z.B. zu den Servern
    von alinotec, Google, Apple u.a.) auf. Software-Updates (so genannte
    „Firmware-Updates“) werden im Normalfall aus Sicherheits- und
    Stabilitätsgründen automatisch in das Produkt von alinotec über das Internet
    eingespielt, sofern technisch möglich. 7.2 Bei der Nutzung der Dienste und
    Software von alinotec können Kosten für Datenübertragungen bei dem
    Mobilfunkanbieter oder Internet Service Provider des Kunden anfallen. Diese
    Kosten richten sich nach den von dem Kunden geschlossenen Verträgen und
    gehen zu Lasten des Kunden. IV. Sonstiges 1. Auskunftsermächtigung Der Kunde
    erklärt sich mit seiner Unterschrift zur Auftragserteilung damit
    einverstanden, dass alinotec im Zusammenhang mit der Annahme und Abwicklung
    des Vertrages Auskünfte bei Hausbanken, Wirtschaftsauskunfteien (u. a.
    Creditreform, Bürgel, Schufa etc.) einholt. Bei negativer Auskunft / Bonität
    ist alinotec berechtigt die Annahme des Vertrages abzulehnen bzw. diesen
    fristlos zu kündigen. 2. Rechtswahl Für den Vertrag mit dem Kunden und die
    gesamten Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik
    Deutschland unter Ausschluss des UN-Kaufrechts (CISG). 3. Erfüllungsort und
    Gerichtsstand Erfüllungsort und ausschließlicher Gerichtsstand für alle
    Streitigkeiten aus und im Zusammenhang mit den Verträgen zwischen dem Kunden
    und alinotec ist der Sitz von alinotec in Büttelborn. 4. Salvatorische
    Klausel Sollten einzelne Bestimmungen dieser Allgemeinen
    Geschäftsbedingungen ganz oder teilweise unwirksam sein oder werden, so wird
    hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Im Fall der
    Unwirksamkeit einer Regelung treten an deren Stelle die einschlägigen
    gesetzlichen Bestimmungen. Stand der AGB: 10.12.2021
  </div>
</template>

<script>
export default {
  name: 'TermsView',
};
</script>

<style>
.roman {
  list-style-type: upper-roman !important;
}
</style>
